
import {
  IonTitle,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonContent
} from "@ionic/vue"

export default {
  name: 'SoftwareUpdate',
  props: ['pageTitle'],
  components: {
    IonTitle,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonContent
  }
}
