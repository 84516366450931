
import BaseLayout from './BaseLayout.vue'

import {
  IonList,
  IonItem,
  alertController
} from "@ionic/vue"

import router from '../router'

export default {
  name: 'CreateWifiAPSuccess',
  components: {
    IonList,
    IonItem,
    BaseLayout
  },
  data: () => {
    return {
      vedaConnected: false,
      softwareUpdated: false,
      wifiCredentialsSetup: false,
      messages: []
    }
  },
  methods: {
    async handleTestConnection() {
      // await this.testVedaConnection()
      // await this.testSoftwareUpdated()
      // await this.testWifiCredentialsSetup()
      const success = await alertController.create({
        header: 'Congratulations',
        message: 'Your VEDA is connected and ready to go',
        buttons: [
          {
            text: 'Open Dashboard',
            handler: () => {
              router.push('/dashboard')
            }
          }
        ]
      })
      return success.present()
    },

    async testVedaConnection() {
      await this.wait()
      debugger
      // this.vedaConnected = true
    },

    async testSoftwareUpdated() {
      await this.wait()
      // this.softwareUpdated = true
    },

    async testWifiCredentialsSetup() {
      await this.wait()
      // this.wifiCredentialsSetup = true
    },

    wait() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({})
        }, 1500)
      })
    }
  }
}
