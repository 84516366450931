<template>
  <base-layout page-title="Connect to a stable wifi connection">
    <template v-slot:main-content>
    <div id="container" class="ion-padding">
      <p>
        To ensure the VEDA data is synced and updated regularly, update the Wi-Fi settings
        to your Home Wi-Fi network, instead of a Hotspot Wi-Fi.
      </p>

      <ion-item>
        <ion-label position="stacked">SSID</ion-label>
        <ion-input></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Password</ion-label>
        <ion-input></ion-input>
      </ion-item>

      <ion-button size="large" expand="block" @click="handleTestConnection">Test Connection With Wi-Fi</ion-button>

      <ion-list>
        <ion-item v-for="message in messages" :key="message">
          <ion-icon name="check" slot="start"></ion-icon>
          <ion-label>{{message}}</ion-label>
        </ion-item>
        <!-- <ion-item v-if="vedaConnected">
            <ion-icon name="check" slot="start"></ion-icon>
            <ion-label>Connecting to VEDA</ion-label>
        </ion-item>
        <ion-item v-if="softwareUpdated">
            <ion-icon name="check" slot="start"></ion-icon>
            <ion-label>Checking for software updates</ion-label>
        </ion-item>
        <ion-item v-if="wifiCredentialsSetup">
            <ion-icon name="check" slot="start"></ion-icon>
            <ion-label>Setting new wifi-credentials</ion-label>
        </ion-item>
        <ion-item v-if="vedaConnected && softwareUpdated && wifiCredentialsSetup">
            <ion-icon name="check" slot="start"></ion-icon>
            <ion-label>Complete</ion-label>
        </ion-item> -->
      </ion-list>
    </div>
    </template>
  </base-layout>
</template>


<script lang="ts">
import BaseLayout from './BaseLayout.vue'

import {
  IonList,
  IonItem,
  alertController
} from "@ionic/vue"

import router from '../router'

export default {
  name: 'CreateWifiAPSuccess',
  components: {
    IonList,
    IonItem,
    BaseLayout
  },
  data: () => {
    return {
      vedaConnected: false,
      softwareUpdated: false,
      wifiCredentialsSetup: false,
      messages: []
    }
  },
  methods: {
    async handleTestConnection() {
      // await this.testVedaConnection()
      // await this.testSoftwareUpdated()
      // await this.testWifiCredentialsSetup()
      const success = await alertController.create({
        header: 'Congratulations',
        message: 'Your VEDA is connected and ready to go',
        buttons: [
          {
            text: 'Open Dashboard',
            handler: () => {
              router.push('/dashboard')
            }
          }
        ]
      })
      return success.present()
    },

    async testVedaConnection() {
      await this.wait()
      debugger
      // this.vedaConnected = true
    },

    async testSoftwareUpdated() {
      await this.wait()
      // this.softwareUpdated = true
    },

    async testWifiCredentialsSetup() {
      await this.wait()
      // this.wifiCredentialsSetup = true
    },

    wait() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({})
        }, 1500)
      })
    }
  }
}
</script>